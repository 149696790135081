
/* eslint-disable */
import { defineComponent, ref } from 'vue';

export default defineComponent({
  components: {
  },
  setup() {
    //#region Data
    const currentYear = ref(new Date().getFullYear());
    //#endregion
    
    //#region Methods
    
    //#endregion
    
    return {
      //#region Data
      currentYear,
      //#endregion
      
      //#region Methods
      
      //#endregion
    }
  },
})
